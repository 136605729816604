/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { deprecatedGmxApiRequest } from '~/backend/gmx-api/core/request'

import DeprecatedGmxApi from '../GmxApi'
import { type Deprecated_PaginationParams } from '../pagination'
import { type AlertStatusType, fromAlertApi } from './Alert'
import { validateAlertApi } from './Alert.validator'
import { fromAlertsApi } from './Alerts'
import { validateAlertsApi } from './Alerts.validator'
import { GET_ALERTS_ERRORS } from './error-codes/get-alerts'
import { UPDATE_ALERT_ERRORS } from './error-codes/update-alert'

export type GetAlertsRequestParams = Deprecated_PaginationParams

interface UpdateAlertRequestParams {
  alertId: string
  status: AlertStatusType
}

export const getAlertsRequest = (params: GetAlertsRequestParams) =>
  deprecatedGmxApiRequest({
    errorCodes: GET_ALERTS_ERRORS,
    request: () => DeprecatedGmxApi.get('/bo/alerts', { params }),
    transform: fromAlertsApi,
    validate: validateAlertsApi,
  })

export const updateAlertRequest = ({ alertId, ...params }: UpdateAlertRequestParams) =>
  deprecatedGmxApiRequest({
    errorCodes: UPDATE_ALERT_ERRORS,
    request: () => DeprecatedGmxApi.patch(`/bo/alerts/${alertId}`, params),
    transform: fromAlertApi,
    validate: validateAlertApi,
  })
