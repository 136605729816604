/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

const AlertStatusTypes = ['NEW', 'RESOLVED'] as const
export type AlertStatusType = (typeof AlertStatusTypes)[number]
const AlertEntryTypes = ['CHALLENGE', 'GAME', 'MOD'] as const
type AlertEntryType = (typeof AlertEntryTypes)[number]
const AlertUpdateTypes = ['DELETED', 'UNPUBLISHED'] as const
type AlertUpdateType = (typeof AlertUpdateTypes)[number]

// typia-generate: AlertApi
export interface AlertApi {
  alertId: string
  /**
   * @format date-time
   */
  createdAt: string
  entityId: string
  entityType: string
  feedUpdateDetails: {
    entryId: string
    entryName: string
    entryType: AlertEntryType
    updateType: AlertUpdateType
  } | null
  status: AlertStatusType
}

export const fromAlertApi = (alert: AlertApi) => ({ ...alert })

export type Alert = ReturnType<typeof fromAlertApi>
