/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { useQuery } from '@tanstack/react-query'

import DeprecatedGmxApi from '../GmxApi'

const BACKEND_VERSION_QUERY_KEY = 'bo.beVersion'

// NOTE(hugom): This doesn't use request.ts because the api response differs too much from regular BO api calls
export const useBackendVersion = () => {
  return useQuery({
    queryFn: async () => {
      try {
        const result = await DeprecatedGmxApi.get('/version')
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return result.data
      } catch (error: unknown) {
        throw error
      }
    },
    queryKey: [BACKEND_VERSION_QUERY_KEY],
  })
}
