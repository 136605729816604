/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { toast } from 'react-hot-toast'

import {
  deprecatedCreateGmxApiMutationHook,
  deprecatedCreateGmxApiQueryHook,
} from '~/backend/gmx-api/core/query'

import { getAlertsRequest, updateAlertRequest } from './getAlertsRequest'

const ALERTS_KEY = 'bo.alerts'

export const useAlerts = deprecatedCreateGmxApiQueryHook(
  (params) => [ALERTS_KEY, params],
  getAlertsRequest,
  {
    refetchOnWindowFocus: true,
  },
)

export const useUpdateAlert = deprecatedCreateGmxApiMutationHook(
  updateAlertRequest,
  (queryClient) => ({
    onMutate: () => queryClient.cancelQueries({ queryKey: [ALERTS_KEY] }),
    onSettled: () => queryClient.invalidateQueries({ queryKey: [ALERTS_KEY] }),
    onSuccess: () => {
      toast.success(`Alert updated`)
    },
  }),
)
