/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

'use client'

import { useEffect } from 'react'

export const DisableInputNumberScroll = () => {
  useEffect(() => {
    const handleFocusChange = () => {
      const active = document.activeElement
      if (!active || !(active instanceof HTMLInputElement) || active.type !== 'number') {
        return
      }

      const preventValueChange = () => {
        active.blur()
        setTimeout(() => active.focus({ preventScroll: true }), 0)
      }

      active.addEventListener('wheel', preventValueChange)
      active.addEventListener(
        'blur',
        () => {
          active.removeEventListener('wheel', preventValueChange)
        },
        { once: true },
      )
    }

    document.addEventListener('focusin', handleFocusChange)
    return () => {
      document.removeEventListener('focusin', handleFocusChange)
    }
  }, [])

  return null
}
