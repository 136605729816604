/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '~/utils/cn'

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none disabled:opacity-50',
  {
    defaultVariants: {
      size: 'default',
      variant: 'primary',
    },
    variants: {
      size: {
        default: 'min-h-9 px-4',
        icon: 'aspect-square min-h-5 min-w-5',
        lg: 'min-h-10 px-8',
        sm: 'min-h-8 rounded px-3 text-xs',
      },
      variant: {
        danger: 'bg-[#dc3545] text-[#ffffff] shadow hover:bg-[#dc3545]/90 active:bg-[#dc3545]/80',
        dark: 'bg-[#212529] text-[#f8f9fa] shadow hover:bg-[#424649] active:bg-[#4d5154]',
        info: 'bg-[#0dcaf0] text-[#000000] shadow hover:bg-[#0dcaf0]/90 active:bg-[#0dcaf0]/80',
        light: 'bg-[#f8f9fa] text-[#000000] shadow hover:bg-[#f8f9fa]/90 active:bg-[#f8f9fa]/80',
        'outline-danger':
          'border border-[#dc3545] text-[#dc3545] shadow hover:bg-[#dc3545] hover:text-[#ffffff] active:bg-[#b02a37] active:text-[#ffffff]',
        'outline-dark':
          'border border-[#212529] text-[#212529] shadow hover:bg-[#212529] hover:text-[#ffffff] active:bg-[#1a1e22] active:text-[#ffffff]',
        'outline-info':
          'border border-[#0dcaf0] text-[#0dcaf0] shadow hover:bg-[#0dcaf0] hover:text-[#000000] active:bg-[#0a94c1] active:text-[#000000]',
        'outline-light':
          'border border-[#f8f9fa] text-[#f8f9fa] shadow hover:bg-[#f8f9fa] hover:text-[#000000] active:bg-[#cfd3d7] active:text-[#000000]',
        'outline-primary':
          'border border-[#0d6efd] text-[#0d6efd] shadow hover:bg-[#0d6efd] hover:text-[#ffffff] active:bg-[#0a58ca] active:text-[#ffffff]',
        'outline-secondary':
          'border border-[#6c757d] text-[#6c757d] shadow hover:bg-[#6c757d] hover:text-[#ffffff] active:bg-[#565e64] active:text-[#ffffff]',
        'outline-success':
          'border border-[#198754] text-[#198754] shadow hover:bg-[#198754] hover:text-[#ffffff] active:bg-[#146c43] active:text-[#ffffff]',
        'outline-warning':
          'border border-[#ffc107] text-[#ffc107] shadow hover:bg-[#ffc107] hover:text-[#000000] active:bg-[#cc9a06] active:text-[#000000]',
        primary: 'bg-[#0d6efd] text-[#ffffff] shadow hover:bg-[#0d6efd]/90 active:bg-[#0d6efd]/80',
        secondary:
          'bg-[#6c757d] text-[#ffffff] shadow hover:bg-[#6c757d]/90 active:bg-[#6c757d]/80',
        success: 'bg-[#198754] text-[#ffffff] shadow hover:bg-[#198754]/90 active:bg-[#198754]/80',
        warning: 'bg-[#ffc107] text-[#000000] shadow hover:bg-[#ffc107]/90 active:bg-[#ffc107]/80',
      },
    },
  },
)
export type ButtonVariant = VariantProps<typeof buttonVariants>['variant']

type ButtonProps = {
  asChild?: boolean
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants>

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ asChild = false, className, size, variant, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp ref={ref} className={cn(buttonVariants({ className, size, variant }))} {...props} />
    )
  },
)
Button.displayName = 'Button'

export { Button }
