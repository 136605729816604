/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

'use client'

import styled from 'styled-components'

import { useBackendVersion } from '~/backend/gmx-api/be-version/BackendVersion.query'

export default function Footer() {
  const backendVersion = useBackendVersion()

  return (
    <Wrapper>
      <BackendVersionLabel
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className={'text-muted'}
      >
        Backend version: {backendVersion.data ?? '0.0.0'}
      </BackendVersionLabel>
    </Wrapper>
  )
}

const BackendVersionLabel = styled.label`
  display: flex;

  font-size: 0.875em;
`
const Wrapper = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 32px;
  margin-right: 16px;
  margin-left: 16px;
  padding: 32px;

  border-top: 3px solid rgb(255 255 255 / 10%);
`
