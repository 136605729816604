/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

'use client'

import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { type PropsWithChildren, Suspense, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { ThemeProvider } from 'styled-components'

import { TooltipProvider } from '~/components/Tooltip'
import { OperaDevTools } from '~/features/dev/OperaDevTools'
import { NoPermissions } from '~/features/permission/NoPermissions'

import { DisableInputNumberScroll } from './DisableInputNumberScroll'
import { getQueryClient } from './query-client'
import { styledColors } from './styled-palette'

const styledTheme = { colors: styledColors }

export default function Providers({ children }: PropsWithChildren) {
  const [queryClient] = useState(getQueryClient)

  return (
    <>
      <DisableInputNumberScroll />
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={styledTheme}>
          <TooltipProvider delayDuration={0}>
            <Toaster position="top-center" toastOptions={{ className: '!max-w-xl' }} />
            <NoPermissions />
            <Suspense fallback={null}>{children}</Suspense>
            <ReactQueryDevtools buttonPosition="bottom-left" initialIsOpen={false} />
            <OperaDevTools />
          </TooltipProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  )
}
