/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

// swagger-error-diffs: group=BO patch /bo/alerts/{alertId}
export const UPDATE_ALERT_ERRORS = [
  // validation error in payload request
  'invalid_request_payload',
  // the request resulted in no changes
  'no_changes',

  // the user must be signed in
  'sign_in_required',

  // system alert id not valid
  'system_alert_not_found',
] as const
