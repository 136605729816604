/* tslint:disable */
import typia from 'typia';
import { AlertApi } from '~/backend/gmx-api/alerts/Alert';
export const validateAlertApi = (() => { const $guard = (typia.createAssert as any).guard; const $io0 = (input: any): boolean => "string" === typeof input.alertId && ("string" === typeof input.createdAt && /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])(T|\s)([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9](?:\.[0-9]{1,9})?(Z|[+-]([01][0-9]|2[0-3]):[0-5][0-9])$/i.test(input.createdAt)) && "string" === typeof input.entityId && "string" === typeof input.entityType && (null === input.feedUpdateDetails || "object" === typeof input.feedUpdateDetails && null !== input.feedUpdateDetails && $io1(input.feedUpdateDetails)) && ("NEW" === input.status || "RESOLVED" === input.status); const $io1 = (input: any): boolean => "string" === typeof input.entryId && "string" === typeof input.entryName && ("CHALLENGE" === input.entryType || "GAME" === input.entryType || "MOD" === input.entryType) && ("DELETED" === input.updateType || "UNPUBLISHED" === input.updateType); const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.alertId || $guard(_exceptionable, {
    path: _path + ".alertId",
    expected: "string",
    value: input.alertId
}, _errorFactory)) && ("string" === typeof input.createdAt && (/^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])(T|\s)([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9](?:\.[0-9]{1,9})?(Z|[+-]([01][0-9]|2[0-3]):[0-5][0-9])$/i.test(input.createdAt) || $guard(_exceptionable, {
    path: _path + ".createdAt",
    expected: "string & Format<\"date-time\">",
    value: input.createdAt
}, _errorFactory)) || $guard(_exceptionable, {
    path: _path + ".createdAt",
    expected: "(string & Format<\"date-time\">)",
    value: input.createdAt
}, _errorFactory)) && ("string" === typeof input.entityId || $guard(_exceptionable, {
    path: _path + ".entityId",
    expected: "string",
    value: input.entityId
}, _errorFactory)) && ("string" === typeof input.entityType || $guard(_exceptionable, {
    path: _path + ".entityType",
    expected: "string",
    value: input.entityType
}, _errorFactory)) && (null === input.feedUpdateDetails || ("object" === typeof input.feedUpdateDetails && null !== input.feedUpdateDetails || $guard(_exceptionable, {
    path: _path + ".feedUpdateDetails",
    expected: "(__type | null)",
    value: input.feedUpdateDetails
}, _errorFactory)) && $ao1(input.feedUpdateDetails, _path + ".feedUpdateDetails", true && _exceptionable) || $guard(_exceptionable, {
    path: _path + ".feedUpdateDetails",
    expected: "(__type | null)",
    value: input.feedUpdateDetails
}, _errorFactory)) && ("NEW" === input.status || "RESOLVED" === input.status || $guard(_exceptionable, {
    path: _path + ".status",
    expected: "(\"NEW\" | \"RESOLVED\")",
    value: input.status
}, _errorFactory)); const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.entryId || $guard(_exceptionable, {
    path: _path + ".entryId",
    expected: "string",
    value: input.entryId
}, _errorFactory)) && ("string" === typeof input.entryName || $guard(_exceptionable, {
    path: _path + ".entryName",
    expected: "string",
    value: input.entryName
}, _errorFactory)) && ("CHALLENGE" === input.entryType || "GAME" === input.entryType || "MOD" === input.entryType || $guard(_exceptionable, {
    path: _path + ".entryType",
    expected: "(\"CHALLENGE\" | \"GAME\" | \"MOD\")",
    value: input.entryType
}, _errorFactory)) && ("DELETED" === input.updateType || "UNPUBLISHED" === input.updateType || $guard(_exceptionable, {
    path: _path + ".updateType",
    expected: "(\"DELETED\" | \"UNPUBLISHED\")",
    value: input.updateType
}, _errorFactory)); const __is = (input: any): input is AlertApi => "object" === typeof input && null !== input && $io0(input); let _errorFactory: any; return (input: any, errorFactory?: (p: import("typia").TypeGuardError.IProps) => Error): AlertApi => {
    if (false === __is(input)) {
        _errorFactory = errorFactory;
        ((input: any, _path: string, _exceptionable: boolean = true) => ("object" === typeof input && null !== input || $guard(true, {
            path: _path + "",
            expected: "AlertApi",
            value: input
        }, _errorFactory)) && $ao0(input, _path + "", true) || $guard(true, {
            path: _path + "",
            expected: "AlertApi",
            value: input
        }, _errorFactory))(input, "$input", true);
    }
    return input;
}; })();
