/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

// swagger-error-diffs: group=BO get /bo/alerts
export const GET_ALERTS_ERRORS = [
  // invalid page parameter value; must be a valid decimal integer
  'page_invalid',
  // invalid page parameter value; must be 0 or higher
  'page_less_than_0',
  // invalid pageSize parameter value; must be a valid decimal integer
  'page_size_invalid',
  // invalid pageSize parameter value; must be 1 or higher
  'page_size_less_than_1',
  // the page size exceeds the maximum limit
  'page_size_too_high',

  // the user must be signed in
  'sign_in_required',
] as const
