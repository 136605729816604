/* tslint:disable */
import typia from 'typia';
import { AlertsApi } from '~/backend/gmx-api/alerts/Alerts';
export const validateAlertsApi = (() => { const $guard = (typia.createAssert as any).guard; const $io0 = (input: any): boolean => Array.isArray(input.alerts) && input.alerts.every((elem: any) => "object" === typeof elem && null !== elem && $io1(elem)) && ("object" === typeof input.pagination && null !== input.pagination && $io3(input.pagination)); const $io1 = (input: any): boolean => "string" === typeof input.alertId && ("string" === typeof input.createdAt && /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])(T|\s)([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9](?:\.[0-9]{1,9})?(Z|[+-]([01][0-9]|2[0-3]):[0-5][0-9])$/i.test(input.createdAt)) && "string" === typeof input.entityId && "string" === typeof input.entityType && (null === input.feedUpdateDetails || "object" === typeof input.feedUpdateDetails && null !== input.feedUpdateDetails && $io2(input.feedUpdateDetails)) && ("NEW" === input.status || "RESOLVED" === input.status); const $io2 = (input: any): boolean => "string" === typeof input.entryId && "string" === typeof input.entryName && ("CHALLENGE" === input.entryType || "GAME" === input.entryType || "MOD" === input.entryType) && ("DELETED" === input.updateType || "UNPUBLISHED" === input.updateType); const $io3 = (input: any): boolean => "number" === typeof input.currPage && "number" === typeof input.numPerPage && "number" === typeof input.totalItems && "number" === typeof input.totalPages; const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ((Array.isArray(input.alerts) || $guard(_exceptionable, {
    path: _path + ".alerts",
    expected: "Array<AlertApi>",
    value: input.alerts
}, _errorFactory)) && input.alerts.every((elem: any, _index2: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
    path: _path + ".alerts[" + _index2 + "]",
    expected: "AlertApi",
    value: elem
}, _errorFactory)) && $ao1(elem, _path + ".alerts[" + _index2 + "]", true && _exceptionable) || $guard(_exceptionable, {
    path: _path + ".alerts[" + _index2 + "]",
    expected: "AlertApi",
    value: elem
}, _errorFactory)) || $guard(_exceptionable, {
    path: _path + ".alerts",
    expected: "Array<AlertApi>",
    value: input.alerts
}, _errorFactory)) && (("object" === typeof input.pagination && null !== input.pagination || $guard(_exceptionable, {
    path: _path + ".pagination",
    expected: "Deprecated_Pagination",
    value: input.pagination
}, _errorFactory)) && $ao3(input.pagination, _path + ".pagination", true && _exceptionable) || $guard(_exceptionable, {
    path: _path + ".pagination",
    expected: "Deprecated_Pagination",
    value: input.pagination
}, _errorFactory)); const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.alertId || $guard(_exceptionable, {
    path: _path + ".alertId",
    expected: "string",
    value: input.alertId
}, _errorFactory)) && ("string" === typeof input.createdAt && (/^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])(T|\s)([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9](?:\.[0-9]{1,9})?(Z|[+-]([01][0-9]|2[0-3]):[0-5][0-9])$/i.test(input.createdAt) || $guard(_exceptionable, {
    path: _path + ".createdAt",
    expected: "string & Format<\"date-time\">",
    value: input.createdAt
}, _errorFactory)) || $guard(_exceptionable, {
    path: _path + ".createdAt",
    expected: "(string & Format<\"date-time\">)",
    value: input.createdAt
}, _errorFactory)) && ("string" === typeof input.entityId || $guard(_exceptionable, {
    path: _path + ".entityId",
    expected: "string",
    value: input.entityId
}, _errorFactory)) && ("string" === typeof input.entityType || $guard(_exceptionable, {
    path: _path + ".entityType",
    expected: "string",
    value: input.entityType
}, _errorFactory)) && (null === input.feedUpdateDetails || ("object" === typeof input.feedUpdateDetails && null !== input.feedUpdateDetails || $guard(_exceptionable, {
    path: _path + ".feedUpdateDetails",
    expected: "(__type | null)",
    value: input.feedUpdateDetails
}, _errorFactory)) && $ao2(input.feedUpdateDetails, _path + ".feedUpdateDetails", true && _exceptionable) || $guard(_exceptionable, {
    path: _path + ".feedUpdateDetails",
    expected: "(__type | null)",
    value: input.feedUpdateDetails
}, _errorFactory)) && ("NEW" === input.status || "RESOLVED" === input.status || $guard(_exceptionable, {
    path: _path + ".status",
    expected: "(\"NEW\" | \"RESOLVED\")",
    value: input.status
}, _errorFactory)); const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.entryId || $guard(_exceptionable, {
    path: _path + ".entryId",
    expected: "string",
    value: input.entryId
}, _errorFactory)) && ("string" === typeof input.entryName || $guard(_exceptionable, {
    path: _path + ".entryName",
    expected: "string",
    value: input.entryName
}, _errorFactory)) && ("CHALLENGE" === input.entryType || "GAME" === input.entryType || "MOD" === input.entryType || $guard(_exceptionable, {
    path: _path + ".entryType",
    expected: "(\"CHALLENGE\" | \"GAME\" | \"MOD\")",
    value: input.entryType
}, _errorFactory)) && ("DELETED" === input.updateType || "UNPUBLISHED" === input.updateType || $guard(_exceptionable, {
    path: _path + ".updateType",
    expected: "(\"DELETED\" | \"UNPUBLISHED\")",
    value: input.updateType
}, _errorFactory)); const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.currPage || $guard(_exceptionable, {
    path: _path + ".currPage",
    expected: "number",
    value: input.currPage
}, _errorFactory)) && ("number" === typeof input.numPerPage || $guard(_exceptionable, {
    path: _path + ".numPerPage",
    expected: "number",
    value: input.numPerPage
}, _errorFactory)) && ("number" === typeof input.totalItems || $guard(_exceptionable, {
    path: _path + ".totalItems",
    expected: "number",
    value: input.totalItems
}, _errorFactory)) && ("number" === typeof input.totalPages || $guard(_exceptionable, {
    path: _path + ".totalPages",
    expected: "number",
    value: input.totalPages
}, _errorFactory)); const __is = (input: any): input is AlertsApi => "object" === typeof input && null !== input && $io0(input); let _errorFactory: any; return (input: any, errorFactory?: (p: import("typia").TypeGuardError.IProps) => Error): AlertsApi => {
    if (false === __is(input)) {
        _errorFactory = errorFactory;
        ((input: any, _path: string, _exceptionable: boolean = true) => ("object" === typeof input && null !== input || $guard(true, {
            path: _path + "",
            expected: "AlertsApi",
            value: input
        }, _errorFactory)) && $ao0(input, _path + "", true) || $guard(true, {
            path: _path + "",
            expected: "AlertsApi",
            value: input
        }, _errorFactory))(input, "$input", true);
    }
    return input;
}; })();
