/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import styled from 'styled-components'

export const Banner = styled.div<{ $show: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: ${({ $show }) => ($show ? '10vh' : '0')};
  padding: ${({ $show }) => `${$show ? '16px' : 0} calc(min(142px, max(8vw, 16px)))`};
  overflow: hidden;

  transition:
    max-height 0.15s ease-out,
    padding 0.15s ease-out;

  background-color: ${({ theme }) => theme.colors.error};

  text-align: center;
  vertical-align: middle;
`
