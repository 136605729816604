/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { type Profile } from '../profile/Profile'

export const isAdmin = (profile: Profile | undefined) => profile?.roles.includes('ADMIN') ?? false

export const isContentManager = (profile: Profile | undefined) =>
  profile?.roles.includes('CONTENT_MANAGER') ?? false

export const isViewer = (profile: Profile | undefined) => profile?.roles.includes('VIEWER') ?? false

export const isGmOps = (profile: Profile | undefined) => profile?.roles.includes('GM_OPS') ?? false

export const isDynamicConfigManager = (profile: Profile | undefined) =>
  profile?.roles.includes('DYNAMIC_CONFIG_MANAGER') ?? false

export const isOfferManager = (profile: Profile | undefined) =>
  profile?.roles.includes('OFFER_MANAGER') ?? false
