/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

/**
 * @deprecated Use tailwind instead.
 */
export const styledColors = {
  accentContrast: 'hsl(var(--gx-accent-100-contrast))',
  error: 'hsl(var(--gx-warning-red-l-100))',
  error120: 'hsl(var(--gx-warning-red-l-120))',
  error140: 'hsl(var(--gx-warning-red-l-140))',
  gray10: 'hsl(var(--gx-gray-10))',
  gray16: 'hsl(var(--gx-gray-16))',
  gray20: 'hsl(var(--gx-gray-20))',
  gray30: 'hsl(var(--gx-gray-30))',
  gray40: 'hsl(var(--gx-gray-40))',
  gray50: 'hsl(var(--gx-gray-50))',
  gray60: 'hsl(var(--gx-gray-60))',
  gray70: 'hsl(var(--gx-gray-70))',
  gray80: 'hsl(var(--gx-gray-80))',
  gray90: 'hsl(var(--gx-gray-90))',
  neutral00: 'hsl(var(--gx-no-00))',
  neutral04: 'hsl(var(--gx-no-04))',
  neutral08: 'hsl(var(--gx-no-08))',
  neutral100: 'hsl(var(--gx-no-100))',
  neutral12: 'hsl(var(--gx-no-12))',
  neutral16: 'hsl(var(--gx-no-16))',
  neutral20: 'hsl(var(--gx-no-20))',
  neutral24: 'hsl(var(--gx-no-24))',
  neutral32: 'hsl(var(--gx-no-32))',
  neutral40: 'hsl(var(--gx-no-40))',
  neutral59: 'hsl(var(--gx-no-59))',
  neutral77: 'hsl(var(--gx-no-77))',
  neutral80: 'hsl(var(--gx-no-80))',
  neutral88: 'hsl(var(--gx-no-88))',
  neutral90: 'hsl(var(--gx-no-90))',
  neutral92: 'hsl(var(--gx-no-92))',
  neutral96: 'hsl(var(--gx-no-96))',
  neutral98: 'hsl(var(--gx-no-98))',
  primary: 'hsl(var(--gx-accent-l-100))',
  primary08: 'hsl(var(--gx-accent-l-8))',
  primary100: 'hsl(var(--gx-accent-l-100))',
  primary120: 'hsl(var(--gx-accent-l-120))',
  primary140: 'hsl(var(--gx-accent-l-140))',
  primary16: 'hsl(var(--gx-accent-l-16))',
  primary160: 'hsl(var(--gx-accent-l-160))',
  primary24: 'hsl(var(--gx-accent-l-24))',
  primary30: 'hsl(var(--gx-accent-l-30))',
  primary60: 'hsl(var(--gx-accent-l-60))',
  primary80: 'hsl(var(--gx-accent-l-80))',
  warning: 'hsl(var(--gx-warning-yellow-l-100))',
  warning120: 'hsl(var(--gx-warning-yellow-l-120))',
  warning140: 'hsl(var(--gx-warning-yellow-l-140))',
}

/**
 * @deprecated Use tailwind instead.
 */
export type StyledColors = typeof styledColors
