/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { type Deprecated_Pagination } from '../pagination'
import { type AlertApi, fromAlertApi } from './Alert'

// typia-generate: AlertsApi
export interface AlertsApi {
  alerts: AlertApi[]
  pagination: Deprecated_Pagination
}

export const fromAlertsApi = ({ alerts, pagination }: AlertsApi) => ({
  alerts: alerts.map(fromAlertApi),
  pagination,
})
