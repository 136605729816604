/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'
import Cookies from 'js-cookie'
import toast from 'react-hot-toast'
import { z } from 'zod'

import { ClientError, ServerError } from '~/backend/bo/core'
import { GMX_API } from '~/config'
import { REDIRECT_URL_KEY } from '~/utils/consts'

/**
 * @deprecated Legacy schema
 */
const apiErrorSchema = z.union([
  z.object({
    errors: z.array(z.object({ code: z.string() })),
    type: z.literal('client error'),
  }),
  z.object({
    error: z.instanceof(Error),
    type: z.literal('server error'),
  }),
  z.object({
    error: z.instanceof(Error),
    type: z.literal('network error'),
  }),
])

function retry(count: number, error: unknown) {
  // Legacy: Don't retry client errors
  {
    const result = apiErrorSchema.safeParse(error)
    if (result.success && result.data.type === 'client error') {
      return false
    }
  }

  // Don't retry client errors
  if (error instanceof ClientError) {
    return false
  }

  return count < 2
}

function handleLogin() {
  if (location.pathname === '/error-login/') {
    return
  }

  Cookies.set(REDIRECT_URL_KEY, window.location.pathname + window.location.search)
  window.location.assign(new URL('/bo/session/login', GMX_API).toString())
}

function onError(error: Error) {
  if (error instanceof Error && error.message === 'Network Error') {
    return toast.error('Cannot connect. Please check your internet connection', {
      id: 'network-error',
    })
  }
  if (error instanceof ClientError) {
    if (error.code === 'sign_in_required') {
      handleLogin()
      return
    }
    toast.error(`Client Error: ${error.code}`)
    return
  }
  if (error instanceof ServerError) {
    toast.error(`Backend Error: ${error.message}`)
    return
  }
  const apiError = apiErrorSchema.safeParse(error)
  if (apiError.success) {
    switch (apiError.data.type) {
      case 'client error':
        if (!!apiError.data.errors.find(({ code }) => code === 'sign_in_required')) {
          handleLogin()
          return
        }
        toast.error(`Errors: ${apiError.data.errors.map(({ code }) => code).join(', ')}`)
        break
      case 'network error':
        return toast.error('Cannot connect. Please check your internet connection', {
          id: 'network-error',
        })
      default:
        toast.error(`Error: ${String(apiError.data.error)}`)
        break
    }
    return
  }
  toast.error(`Error: ${String(error)}`)
}

const createQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        retry,
        staleTime: 1000 * 60 * 3,
      },
    },
    mutationCache: new MutationCache({ onError }),
    queryCache: new QueryCache({ onError }),
  })

let clientQueryClientSingleton: QueryClient | undefined = undefined

export const getQueryClient = () => {
  if (typeof window === 'undefined') {
    // Server: always make a new query client
    return createQueryClient()
  }
  // Browser: use singleton pattern to keep the same query client
  return (clientQueryClientSingleton ??= createQueryClient())
}
